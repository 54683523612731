import React from "react";
import MailForm from "./MailForm";

const UslugePaketi = () => {
  return (
    <>
    <div className="home" style={{ height: '100vh' }}></div>
    <div className="container nopad">
      <div class="pricing4 py-5">
        <div class="container">
          <div class="row mt-4">
            <div class="col-md-4">
              <div class="card card-shadow border-0 mb-4">
                {/* <img
                  class="card-img-top"
                  src="/img/starter.gif"
                  alt="Starter paket"
                /> */}
                <img
                  class="card-img-top"
                  src="/img/STARTERPACK.gif"
                  alt="business boost"
                />
                <div class="p-3">
                  <h5 class="font-weight-medium mb-0">STARTER PAKET</h5>
                  <ul class="list-inline font-14 mt-3 mediafix">
                    <li class="py-1">
                      ✅ Izrada web stranice (bez načina plaćanja i baze
                      podataka)
                    </li>
                    <li class="py-1">✅ Hosting </li>
                    <li class="py-1">✅ Domen (.com ili .rs) </li>
                    <li class="py-1">
                      ✅ SEO optimizacija za poboljšanu vidljivost
                    </li>
                  </ul>
                </div>

                <div class="d-flex flex-column justify-content-between mt-auto">
                  <div className="text-center">
                    {/* <h2 class="price">130€</h2> */}
                    <a
                      class="btn btn-danger-gradiant rounded-pill text-white btn-md border-0"
                      
                    >
                      200€
                      
                    </a>
                  </div>
                </div>
              </div>
            </div>
            

            <div class="col-md-4">
              <div class="card card-shadow border-0 mb-4">
                <img
                  class="card-img-top"
                  src="/img/BIZNISBOOST.gif"
                  alt="business boost"
                />
                <div class="p-3">
                  <h5 class="font-weight-medium mb-0">BUSINESS BOOST</h5>
                  <ul class="list-inline font-14 mt-3">
                    <li class="py-1">✅ STARTER PAKET +</li>
                    <li class="py-1">✅ Održavanje web stranice</li>
                    <li class="py-1">✅ Kreiranje logotipa</li>
                  </ul>
                </div>

                <div class="d-flex flex-column justify-content-between mt-auto">
                  <div className="text-center">
                    {/* <h2 class="price">150€</h2>    */}
                    <a
                      class="btn btn-danger-gradiant rounded-pill text-white btn-md border-0"
                      
                    >
                      270€
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card card-shadow border-0 mb-4">
                <img
                  class="card-img-top"
                  src="/img/PREMIUM.gif"
                  alt="Premium paket"
                />
                <div class="p-3">
                  <h5 class="font-weight-medium mb-0">PREMIUM PAKET</h5>
                  <ul class="list-inline font-14 mt-3">
                    <li class="py-1">✅ Business boost + </li>
                    <li class="py-1">
                      ✅ Dodavanje načina plaćanja na web stranici
                    </li>
                    <li class="py-1">
                      ✅ Neograničen broj izmena na web stranici{" "}
                    </li>
                    <li class="py-1">
                      ✅ Sponzorisane reklame (Facebook, Instagram i Google)
                    </li>
                    <li class="py-1">
                      ✅ Kampanja za odredjeni proizvod ili uslugu
                    </li>
                  </ul>
                </div>
                <div class="d-flex flex-column justify-content-between mt-auto">
                  <div className="text-center">
                    {/* <h2 class="price">300€</h2> */}
                    <a
                      class="btn btn-danger-gradiant rounded-pill text-white btn-md border-0"
                      
                    >
                      300€
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      <h2 className="kontaktirajte">KONTAKTIRAJTE NAS</h2>
      <MailForm />
      
    </div>
    </>
  );
};

export default UslugePaketi;
