import React from 'react'

const SocialIcons = () => {
  return (
    <div className='mainHome'>
    <h4>KRENIMO DA STVARAMO ZAJEDNO</h4>
    <ul id="social-icons">
        <li>
          <a href="https://www.facebook.com/oglaszona?mibextid=ZbWKwL" target="_blank" rel="noreferrer">
            <i class="fab fa-facebook-f icon"></i>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/oglas_zona_rs?igsh=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer"><i class="fab fa-instagram icon"></i></a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/oglas-zona-704a682aa?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noreferrer" ><i class="fab fa-linkedin-in icon"></i></a>
        </li>
        <li>
          <a href="mailto: oglaszona@proton.me" target="_blank" rel="noreferrer"><i class="fab fa-brands fa-google icon"></i></a>
        </li>
    </ul>
    </div>
  )
}

export default SocialIcons