import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Navbar from "./Navbar";
import Home from "./Home"
import SocialIcons from "./SocialIcons";
import Firme from "./Firme"
import ONama from "./ONama"
import UslugePaketi from "./UslugePaketi"
import { Routes , Route } from "react-router-dom";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function App() {
  return (
    <div className="App">
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />}> </Route>
          <Route path="/firme" element={<Firme />}> </Route>
          <Route path="/onama" element={<ONama />}> </Route>
          <Route path="/paketi" element={<UslugePaketi />}> </Route>
        </Routes>
        <TawkMessengerReact
                propertyId="65ad92b10ff6374032c31b4c"
                widgetId="1hkn0a1s8"/>
      <SocialIcons/>
    </div>
  );
}

export default App;
