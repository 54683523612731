import React from 'react';

const DownloadPdf = () => {
  return (
    <div style={{ textAlign: 'center', margin: '20px 0' }}>
      <a href="/documents/offer.pdf" download>
        <img
          src="/img/download.jpg"
          alt="Download PDF"
          style={{ width: '250px', cursor: 'pointer' }} // Adjust the width as needed
        />
      </a>
    </div>
  );
}

export default DownloadPdf;
