import React from "react";
import { useState } from "react";

const Firme = () => {
  const [searchQuery, setSearchQuery] = useState("");

  // Filter function to check if a row contains the search query
  const filterRow = (rowContent) => {
    return rowContent.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const anyRowsFiltered =
    filterRow("Adaptacija stanova Željko keramicar") ||
    filterRow("Asfaltiranje Radovanović") ||
    filterRow("Rubefix") ||
    filterRow("Limar Pro") ||
    filterRow("Toranj") ||
    filterRow("Gagi Elektro PR elekticar") ||
    filterRow("Znam Coveka Znam Čoveka") ||
    filterRow("Moleraj pistika Moleraj pištika");

  return (
    <>
      <div className="home" style={{ height: "100vh" }}></div>
      <div>
        <div className="container nopad">
          <div className="search-container">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
              placeholder="Pretraži firmu"
            />
          </div>

          <h1 className="noDisplay">Firme</h1>
          {anyRowsFiltered ? (
            <>
              {filterRow("Znam Coveka Znam Čoveka") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/znam.webp"
                      alt="znam"
                    />
                  </div>

                  <div className="col ponuda col-12 col-md-6">
                    <div className="">
                      <p>
                        "Znam Čoveka" je platforma koja povezuje ljude sa
                        proveravanim i pouzdanim uslugama i stručnjacima. Nudi
                        širok spektar informacija i preporuka kako biste lako
                        pronašli kvalitetne usluge i izbegli neproverene
                        ponuđače. Idealno rešenje za sve vaše potrebe i pitanja,
                        sa fokusom na poverenje i kvalitet.
                      </p>
                      <p className="nomargin">
                        {" "}
                        {/* <a href="tel:+381644271971" className="noblue">
                          Tel - +381644271971{" "}
                        </a>{" "} */}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Moleraj pistika Moleraj pištika") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Moleraj pištika</h2>

                      <p className="nomargin">
                        {" "}
                        Sa višegodišnjim iskustvom, garantujemo visokokvalitetan
                        rad uz pažljivu pripremu površine i Vaš enterijer
                        osvežićemo našim materijalom bez potrebe da ga Vi
                        kupujete. Nedopustite da Vaši molerski i gipsarski
                        radovi budu izvor stresa, kontaktirajte nas danas i
                        osigurajte sebi besprekoran završetak Vašeg projekta.
                      </p>
                      <p className="nomargin">
                        Molerski radovi Pištika Vama na usluzi.
                      </p>
                      <br />
                      <h3>Kontakt:</h3>

                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381638412029" className="noblue">
                          Tel - +381638412029{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/pistika.webp"
                      alt="Moleraj pistika"
                    />
                  </div>
                </div>
              )}

              {filterRow("Adaptacija stanova Željko keramicar") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/keramicar.webp"
                      alt="Keramicar"
                    />
                  </div>

                  <div className="col ponuda col-12 col-md-6">
                    <div className="">
                      <h2>Adaptacija stanova Željko</h2>
                      <h3>Cenovnik adaptacija stana:</h3>
                      <p>Klasična adaptacija 300e </p>
                      <p>Polu lux adaptacija 400e</p>
                      <p>Lux adaptacija 500e</p>
                      <p>Kvadrat keramike 17e</p>
                      <p>Kupatilo komplet 4000e</p>
                      <p>
                        Cene su date za sistem ključ u ruke sa materijalom i
                        majstorima po kvadratu stambenog prostora.
                      </p>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381644271971" className="noblue">
                          Tel - +381644271971{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Asfaltiranje Radovanović") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Asfaltiranje Radovanović</h2>
                      <h3>O nama</h3>

                      <p className="nomargin">
                        {" "}
                        Asfaltiranje Radovanović ima višegodišnje iskustvo u
                        pripremi i asfaltiranju. Naš stručni kadar se sastoji od
                        ljudi koji imaju preko 15 godina radnog iskustva što
                        pokazuje preko 300 uspešno obavljenih poslova bez ijedne
                        reklamacije.{" "}
                      </p>
                      <br />
                      <h3>Kontakt:</h3>

                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381601439074" className="noblue">
                          Tel - +381601439074{" "}
                        </a>{" "}
                      </p>
                      <p className="nomargin">
                        <a
                          href="mailto: milosradovanovic484@gmail.com"
                          target="_blank"
                          rel="noreferrer"
                          className="noblue"
                        >
                          {" "}
                          Email - milosradovanovic484@gmail.com
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/asfalt.jpg"
                      alt="Asfaltiranje Radovanovic"
                    />
                  </div>
                </div>
              )}

              {filterRow("Rubefix") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/rubefix.jpg"
                      alt="Rubefix"
                    />
                  </div>

                  <div className="col ponuda col-12 col-md-6">
                    <div className="">
                      <h2>Rubefix</h2>
                      <p>
                        Rube Clean je agencija za čišćenje stanova, poslovnih
                        prostora i mašinskog čišćenja hala. Cilj naše agencije
                        je da svojim klijentima pružimo profesionalne usluge
                        čišćenja visokog kvaliteta. Naš tim se sastoji od
                        stručnih i obučenih čistača koji će se pobrinuti da vaš
                        prostor sjaji. Koristimo najnoviju opremu i tehnologiju
                        u procesu čišćenja, što nam omogućava da postignemo
                        visok nivo efikasnosti i preciznosti. Pored toga, naša
                        agencija pruža i usluge mašinskog čišćenja hala, gde
                        ćemo se pobrinuti da vaša industrijska hala bude
                        besprekorno čista. Kontaktirajte nas i uverite se u našu
                        posvećenost čistoći i kvalitetu usluge koju pružamo.
                      </p>
                      <h3>Usluge</h3>
                      <p>
                        Čiscenje zgrada, mašinsko čišćenje , čišćenje posle
                        gradjevinskih radova,čišćenje kancelarijskog
                        prostora,održavanje zelenih površina...
                      </p>
                      <p>
                        <i>
                          <a
                            href="mailto: info@rube.rs"
                            target="_blank"
                            rel="noreferrer"
                            className="noblue"
                          >
                            info@rube.rs
                          </a>
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Limar Pro") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Limar Pro</h2>
                      <h3>Usluge</h3>

                      <p className="nomargin"> Horizontalne oluke </p>
                      <p className="nomargin"> Odvodne olucne vertikale</p>
                      <p className="nomargin"> Sve vrste krovnih opsivki</p>
                      <p className="nomargin"> Pokrivanje krova limom</p>
                      <p className="nomargin">
                        {" "}
                        Ugradnja termoizolacionih krovnih panela
                      </p>
                      <p className="nomargin"> Ugradnja zidnih termo panela</p>
                      <p className="nomargin"> Krovne izolacije</p>
                      <p className="nomargin"> Krovne konstrukcije</p>
                      <h3>Kontakt:</h3>

                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381631050336" className="noblue">
                          Tel - +381631050336{" "}
                        </a>{" "}
                      </p>
                      <p className="nomargin">
                        <a
                          href="mailto: belajec@gmail.com"
                          target="_blank"
                          rel="noreferrer"
                          className="noblue"
                        >
                          {" "}
                          Email - belajec@gmail.com
                        </a>{" "}
                      </p>
                      <p className="nomargin"> FB page - Limar - Pro</p>
                    </div>
                  </div>

                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/limarpro.jpg"
                      alt="Limarpro"
                    />
                  </div>
                </div>
              )}

              {filterRow("Toranj") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="col nopad col-12 col-md-6">
                    <img
                      className="slika nopad"
                      src="/img/toranj.webp"
                      alt="Toranj"
                    />
                  </div>

                  <div className="col ponuda col-12 col-md-6">
                    <div className="">
                      <h2>Toranj</h2>
                      <h3>Vladimir Veselinovic</h3>
                      <p>Iz Obrenovca, vrši usluge širom Srbije </p>
                      <p>Ispitivanje zemljišta, uzrokovanje i bušenje bunara</p>
                      <p className="nomargin">
                        {" "}
                        <a href="tel:+38163274757" className="noblue">
                          Tel - +38163274757{" "}
                        </a>{" "}
                      </p>
                      <p>
                        <i>
                          <a
                            href="mailto: vvladimir@beotel.net "
                            target="_blank"
                            rel="noreferrer"
                            className="noblue"
                          >
                            vvladimir@beotel.net
                          </a>
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {filterRow("Gagi Elektro PR elekticar") && (
                <div
                  className="row nopad"
                  style={{
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div
                    className="col ponuda col-12 col-md-6 order-last order-md-first"
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    <div className="">
                      <h2>Gagi Elektro PR</h2>

                      <h3>Usluge</h3>
                      <p className="nomargin">
                        {" "}
                        Sve vrste elektronskih usluga{" "}
                      </p>

                      <h3>Mesto</h3>
                      <p className="nomargin"> Beograd </p>

                      <h3>Mesto rada</h3>
                      <p className="nomargin"> Šire područje Beograda</p>

                      <h3>Kontakt:</h3>

                      <p className="nomargin">
                        {" "}
                        <a href="tel:+381643278044" className="noblue">
                          Tel - +381643278044{" "}
                        </a>{" "}
                      </p>
                    </div>
                  </div>

                  <div className="col nopad col-12 col-md-6 order-first order-md-last">
                    <img
                      className="slika nopad"
                      src="/img/gagi.jpg"
                      alt="Gagi Elektro"
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            <h2 style={{ textAlign: "center" }}>Nema rezultata pretrage</h2>
          )}
        </div>
      </div>
    </>
  );
};

export default Firme;
