import React from 'react'

const ONama = () => {
  return (
    <>
    <div className="home" style={{ height: '100vh' }}></div>
    <div className='mainHome'>
      <h1 className='noDisplay'>O nama</h1>
      <p>Dobrodošli u "Oglas Zona" - gde se susreću potrebe i prilike! 🏢 Ko smo mi? "Oglas Zona" je prostor gde se spajaju poslovne mogućnosti i korisničke potrebe. Sa strašću za povezivanjem, stvaramo most između klijenata i usluga koje menjaju igru.</p>
      <br />
      <h2>🌐 Šta nas izdvaja?</h2>
      <ul>
        <li>Efikasnost i brza realizacija</li>
        <li>Fokus na vašem uspehu</li>
        <li>Raznovrsna ponuda prilika</li>
      </ul>
      <br />
      <p><strong>🚀 Naš cilj:</strong> Da budemo vaša prva destinacija kada je u pitanju ispunjenje potreba i ostvarivanje poslovnih ambicija. "Oglas Zona" je mesto gde se želje pretvaraju u stvarnost.</p>
      <br />
      <p><strong> 🌈 Postanite deo naše zajednice:</strong> Ako želite da vaša usluga ili proizvod bude primećen, "Oglas Zona" je pravo mesto. Pridružite nam se i doprinesite stvaranju bogate mreže poslovnih prilika.</p>
      <br />
      <h4>Posetite nas danas i otkrijte šta znači biti deo "Oglas Zone"!</h4>
    </div>
    </>
  )
}

export default ONama