import React, { useEffect } from 'react';
import './Navbar.css';
import $ from 'jquery';
import { Link } from "react-router-dom";

const Navbar = () => {
  const toggleMenu = () => {
    $('.navTrigger').toggleClass('active');
    $('#mainListDiv').toggleClass('show_list');
    $('#mainListDiv').fadeIn();
  };

  useEffect(() => {
    // Add your JavaScript code here (jQuery code)
    $('.navTrigger').click(toggleMenu);

    // Scroll event listener
    $(window).scroll(function () {
      if ($(document).scrollTop() > 50) {
        $('.nav').addClass('affix');
      } else {
        $('.nav').removeClass('affix');
      }
    });

    // Cleanup the event listener when the component unmounts
    return () => {
      $(window).off('scroll');
    };
  }, []);

  return (
    <>
      <nav className="nav">
        <div className="container cont2">
          <div className="logo">
            <img src="/img/LOGO.jpg" alt="Logo" />
          </div>
          <div id="mainListDiv" className="main_list">
            <ul className="navlinks">
              <li></li> <li></li> 
              <li>
                <Link to="/" onClick={toggleMenu}>Početna</Link>
              </li>
              <li>
                <Link to="/onama" onClick={toggleMenu}>O nama</Link>
              </li>
              <li>
                <Link to="/firme" onClick={toggleMenu}>Firme</Link>
              </li>
              <li>
                <Link to="/paketi" onClick={toggleMenu}>Usluge i paketi</Link>
              </li>
            </ul>
          </div>
          <span className="navTrigger">
            <i></i>
            <i></i>
            <i></i>
          </span>
        </div>
      </nav>
      {/* <div className="home" style={{ height: '100vh' }}></div> */}
    </>
  );
};

export default Navbar;
