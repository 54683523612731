import React from 'react';
import './Navbar.css'; // Import the CSS file

const MailForm = () => {
  return (
    <form className="mail-form" action="https://formsubmit.co/nikola.vasic01@gmail.com" method="POST">
      {/* Add a hidden field to disable reCAPTCHA */}
      <input type="hidden" name="_captcha" value="false" />

      <label htmlFor="name" className="form-label">Ime:</label>
      <input type="text" id="name" name="name" className="form-input" required />

      <label htmlFor="email" className="form-label">Email:</label>
      <input type="email" id="email" name="email" className="form-input" required />

      <label htmlFor="message" className="form-label">Poruka:</label>
      <textarea id="message" name="message" className="form-textarea" rows="4" required></textarea>

      <button type="submit" className="form-button">Pošalji</button>
    </form>
  );
};

export default MailForm;
