import React from 'react';
import DownloadPdf from './DownloadPdf'; // Adjust the path if necessary

const Home = () => {
  return (
    <>
      <div className="home" style={{ height: '100vh' }}></div>
      <DownloadPdf />
      <div className='mainHome'>
        <h1 className='noDisplay'>Oglas Zona</h1>
        {/* <img src="/img/GifZona.gif" className='gifZona' alt='Gif Oglas Zone'/> */}
        <p>Oglas Zona - Povežite Se Sa Pravim Prilikama!</p>
        <p>Dobrodošli na Oglas Zona, digitalnu platformu koja pruža prostor za povezivanje ljudi i pruža mogućnost da otkrijete nove prilike, proizvode i usluge. Naša misija je jednostavna - omogućiti korisnicima da efikasno oglašavaju i pronalaze ono što traže.</p>
        
        <br />
        <strong><h2>Ko smo mi?</h2></strong>
        <p>Oglas Zona je mesto gde se sreću ponuda i potražnja. Bez obzira da li želite prodati nešto, pronaći uslugu, ili jednostavno istraživati, Oglas Zona je tu da vam pomogne. Naša zajednica okuplja pojedince, preduzetnike i kompanije koji dele istu strast - pronaći pravu priliku.</p>
        
        <br />
        <strong><h2>Šta nudimo?</h2></strong>
        <p><strong>1.</strong> Jednostavno Oglašavanje: Postavljanje oglasa na Oglas Zona je brzo, jednostavno i efikasno. Bilo da je u pitanju prodaja proizvoda, usluga ili iznajmljivanje, pružamo vam prostor da se istaknete.</p>
        <p><strong>2.</strong> Širok Spektar Kategorija: Bez obzira na to šta tražite ili nudite, verovatno imamo odgovarajuću kategoriju. Od nekretnina do poslova, automobila do kućnih ljubimaca, Oglas Zona pokriva sve.</p>
        <p><strong>3.</strong> Lokalna Povezanost: Osnovni smo deo lokalne zajednice. Pomažemo vam da pronađete ono što vam je potrebno u vašem okruženju.</p>
     
        <br />
        <h2>Kako nas kontaktirati?</h2>
        <p>Imate pitanja, sugestije ili želite saznati više o nama? Slobodno nas kontaktirajte putem naše kontakt forme na veb sajtu. Naš tim je ovde da vam pomogne. Oglas Zona - Gde Se Prave Prave Veze! Pridružite nam se danas i otkrijte svet prilika koji čeka.</p>
        <i>
          <h3>
            <a href="mailto: oglaszona@gmail.com" target="_blank" className='noblue' rel="noreferrer">oglaszona@gmail.com</a>
            <p className="nomargin">
              {" "}
              <a href="tel:+381611993700" className="noblue">
                0611993700{" "}
              </a>{" "}
            </p>
          </h3>
        </i>
      </div>
    </>
  );
}

export default Home;
